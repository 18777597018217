<template>
  <div class="about">
    <div v-if="pokemon" 
      class="p-4 w-10/12 md:w-8/12 lg:w-6/12 xl:w-4/12  m-auto xl:mt-4 flex justify-center flex-col items-center bg-gray-800 rounded-md shadow-lg">
      <h3 class="text-2xl text-gray-100 capitalize">{{ pokemon.name }}</h3>
        <div class="flex flex-row mt-2">
          <div v-for="(type, idx) in pokemon.types" :key="idx">
            <h5 class="mr-2 rounded-md type text-center" :class="`type-${type.type.name}`">{{ type.type.name }}</h5>
          </div>
        </div>
        <div class="flex min-w-full justify-around rendering-pixelated">
          <img class="min-w-full" :src="pokemon.sprites.front_default" alt="">
        </div>
    </div>
    <div v-else>Loading ...</div>
  </div>
</template>
<script>
import {useRoute} from 'vue-router'
import {reactive, toRefs} from 'vue'

export default {
  setup() {
    const route = useRoute();

    const state = reactive({
      pokemon: null
    })
    
    fetch(`https://pokeapi.co/api/v2/pokemon/${route.params.slug}/`)
      .then((res) => res.json())
      .then((fetchedData) => {
        state.pokemon = fetchedData;        
      })

    return {...toRefs(state)}
  }
}
</script>
<style scoped src="@/assets/pokemon.css">
</style>